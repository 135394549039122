// Icons are set in @src/components/dashboard/nav/NavItem.js
export const dashboardRoutes = [
  {
    name: 'general',
    items: [
      {
        title: 'Overview',
        path: '/dashboard/overview',
        icon: 'home'
      },
      {
        title: 'Products',
        path: '/dashboard/overview/tours',
        icon: 'products'
      },
      {
        title: 'Blogs',
        path: '/dashboard/blog',
        icon: 'blogs'
      },
      {
        title: 'Pages',
        path: '/dashboard/pages',
        icon: 'pages',
        featureFlagged: true
      },
      {
        title: 'Analytics',
        path: '/dashboard/analytics',
        icon: 'analytics'
      },
      {
        title: 'Reviews',
        path: '/dashboard/reviews',
        icon: 'reviews'
      },
      {
        title: 'Business Info',
        path: '/dashboard/overview/general-info',
        icon: 'businessInfo'
      },
      {
        title: 'Leads',
        path: '/dashboard/leads',
        icon: 'leads',
      },
      {
        title: 'Echo Social AI',
        path: '/dashboard/social-media-management',
        icon: 'socialMediaManagement',
      },
      {
        title: 'Marketplace',
        path: '/dashboard/marketplace',
        icon: 'marketplace',
        featureFlagged: true
      },
      {
        title: 'GPT3',
        path: '/dashboard/gpt3',
        icon: '',
        featureFlagged: true
      },
      {
        title: 'Redirects',
        path: '/dashboard/redirects',
        icon: 'redirect',
        featureFlagged: true
      }
    ]
  },
  {
    name: 'admin',
    items: [
      {
        title: 'Settings',
        path: '/dashboard/settings',
        icon: 'home'
      },
      {
        title: 'DNS Setup',
        path: '/dashboard/dns-configuration',
        icon: 'dns'
      },
      {
        title: 'Resources',
        path: 'https://www.youtube.com/watch?v=l5x_Ipwl5-0&ab_channel=Gondola-Tour%26ActivityDirectBookingsPlatform',
        icon: 'resources',
        outbound: true
      },
      {
        title: 'Request a Feature',
        path: 'https://gondola.canny.io/feature-requests',
        icon: 'support',
        outbound: true
      },
      {
        title: 'Billing Portal',
        path: 'https://billing.stripe.com/p/login/8wMdTudIO9IXfDy5kk',
        icon: 'billing',
        outbound: true
      }
    ]
  }
];

export const smmDashboardRoutes = [
  {
    name: 'general',
    items: [
      // Trying to reduce user confusion
      // {
      //   title: 'Website',
      //   path: '/dashboard/premium-invite',
      //   icon: 'home',
      //   disabled: true
      // },
      {
        title: 'Review sources',
        path: '/dashboard/reviews',
        icon: 'reviews',
        disabled: false
      },
      {
        title: 'Echo Social AI',
        path: '/dashboard/social-media-management',
        icon: 'socialMediaManagement',
        disabled: false
      }
    ]
  },
  {
    name: 'admin',
    items: [
      {
        title: 'Settings',
        path: '/dashboard/settings',
        icon: 'home',
        disabled: false
      },
      {
        title: 'Support',
        path: 'https://form.typeform.com/to/WoFPo35c',
        icon: 'support-rescue',
        disabled: false,
        outbound: true
      }
    ]
  }
]
;

